//Colors CAWMedia
$space: rgb(255,255,255);
$blue: rgb(1,68,149);
$blue-light: rgba($blue, 0.03);
$turkis: rgb(146,205,179);
$yellow: rgb(249,186,53);
$cloud: rgb(243,243,243);
$grey: #c9c9c9;
$gardient1: linear-gradient(100deg, rgba(1,68,149,1) 0%, rgba(19,85,156,1) 60%, rgba(146,205,203,1) 100%);
$gardient2: linear-gradient(360deg, rgba(1,68,149,1) 0%, rgba(19,85,156,1) 60%, rgba(146,205,203,1) 100%);
$highlight: $blue;

@import "settings";
$medium-gray: lighten($dark-gray, 30);
@import "../node_modules/foundation-sites/scss/foundation";
$fa-font-path: "/fonts/";

@import "../node_modules/@fortawesome/fontawesome-pro/css/all";

@import "_styles/fonts";
@include foundation-everything(true);

@import "../node_modules/owl.carousel/src/scss/owl.carousel";
@import "../node_modules/owl.carousel/src/scss/owl.theme.default";
@import "../node_modules/foundation-datepicker/scss/foundation-datepicker";

@import "_styles/layout";


