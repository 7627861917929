@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 300;
  src: url('../fonts/roboto-v18-latin-300.eot');
  src: local("Roboto Light"), local("Roboto-Light"), url('../fonts/roboto-v18-latin-300.eot?#iefix') format("embedded-opentype"), url('../fonts/roboto-v18-latin-300.woff2') format("woff2"), url('../fonts/roboto-v18-latin-300.woff') format("woff"), url('../fonts/roboto-v18-latin-300.ttf') format("truetype"), url('../fonts/roboto-v18-latin-300.svg#Roboto') format("svg"); }


@font-face {
  font-family: 'Roboto';
  font-style: italic;
  font-weight: 300;
  src: url('../fonts/roboto-v18-latin-300italic.eot');
  src: local("Roboto Light Italic"), local("Roboto-LightItalic"), url('../fonts/roboto-v18-latin-300italic.eot?#iefix') format("embedded-opentype"), url('../fonts/roboto-v18-latin-300italic.woff2') format("woff2"), url('../fonts/roboto-v18-latin-300italic.woff') format("woff"), url('../fonts/roboto-v18-latin-300italic.ttf') format("truetype"), url('../fonts/roboto-v18-latin-300italic.svg#Roboto') format("svg"); }


@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  src: url('../fonts/roboto-v18-latin-regular.eot');
  src: local("Roboto"), local("Roboto-Regular"), url('../fonts/roboto-v18-latin-regular.eot?#iefix') format("embedded-opentype"), url('../fonts/roboto-v18-latin-regular.woff2') format("woff2"), url('../fonts/roboto-v18-latin-regular.woff') format("woff"), url('../fonts/roboto-v18-latin-regular.ttf') format("truetype"), url('../fonts/roboto-v18-latin-regular.svg#Roboto') format("svg"); }


@font-face {
  font-family: 'Roboto';
  font-style: italic;
  font-weight: 400;
  src: url('../fonts/roboto-v18-latin-italic.eot');
  src: local("Roboto Italic"), local("Roboto-Italic"), url('../fonts/roboto-v18-latin-italic.eot?#iefix') format("embedded-opentype"), url('../fonts/roboto-v18-latin-italic.woff2') format("woff2"), url('../fonts/roboto-v18-latin-italic.woff') format("woff"), url('../fonts/roboto-v18-latin-italic.ttf') format("truetype"), url('../fonts/roboto-v18-latin-italic.svg#Roboto') format("svg"); }


@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  src: url('../fonts/roboto-v18-latin-500.eot');
  src: local("Roboto Medium"), local("Roboto-Medium"), url('../fonts/roboto-v18-latin-500.eot?#iefix') format("embedded-opentype"), url('../fonts/roboto-v18-latin-500.woff2') format("woff2"), url('../fonts/roboto-v18-latin-500.woff') format("woff"), url('../fonts/roboto-v18-latin-500.ttf') format("truetype"), url('../fonts/roboto-v18-latin-500.svg#Roboto') format("svg"); }


@font-face {
  font-family: 'Roboto';
  font-style: italic;
  font-weight: 500;
  src: url('../fonts/roboto-v18-latin-500italic.eot');
  src: local("Roboto Medium Italic"), local("Roboto-MediumItalic"), url('../fonts/roboto-v18-latin-500italic.eot?#iefix') format("embedded-opentype"), url('../fonts/roboto-v18-latin-500italic.woff2') format("woff2"), url('../fonts/roboto-v18-latin-500italic.woff') format("woff"), url('../fonts/roboto-v18-latin-500italic.ttf') format("truetype"), url('../fonts/roboto-v18-latin-500italic.svg#Roboto') format("svg"); }


@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  src: url('../fonts/roboto-v18-latin-700.eot');
  src: local("Roboto Bold"), local("Roboto-Bold"), url('../fonts/roboto-v18-latin-700.eot?#iefix') format("embedded-opentype"), url('../fonts/roboto-v18-latin-700.woff2') format("woff2"), url('../fonts/roboto-v18-latin-700.woff') format("woff"), url('../fonts/roboto-v18-latin-700.ttf') format("truetype"), url('../fonts/roboto-v18-latin-700.svg#Roboto') format("svg"); }


@font-face {
  font-family: 'Roboto';
  font-style: italic;
  font-weight: 700;
  src: url('../fonts/roboto-v18-latin-700italic.eot');
  src: local("Roboto Bold Italic"), local("Roboto-BoldItalic"), url('../fonts/roboto-v18-latin-700italic.eot?#iefix') format("embedded-opentype"), url('../fonts/roboto-v18-latin-700italic.woff2') format("woff2"), url('../fonts/roboto-v18-latin-700italic.woff') format("woff"), url('../fonts/roboto-v18-latin-700italic.ttf') format("truetype"), url('../fonts/roboto-v18-latin-700italic.svg#Roboto') format("svg"); }
