body {
  font-family: $body-font-family;
  font-weight: 300;
  font-size: 16px;
 }  //hyphens: auto
h1 {
  color: $yellow;
  font-weight: 500;
  font-size: 1.953em; }
h2 {
  color: $yellow;
  font-weight: 400;
  font-size: 1.563em; }
h3 {
  color: $blue;
  font-weight: 400;
  font-size: 1.25em; }
h4 {
  color: $dark-gray;
  font-weight: 500;
  font-size: 1.25em;
  margin-bottom: 1rem; }
hr {
  color: $cloud;
  width: 60%;
  align-self: center; }
label {
  color: $dark-gray; }
strong {
  font-weight: 400; }
.kicker {
  color: $medium-gray;
  font-weight: 400;
  font-size: 1.25rem; }
.subheadline {
  font-size: 1.563em;
  font-weight: 400;
  color: $blue;
  margin: -.6rem 0 0 0; }

.button {
  font-weight: 400;
  border-radius: 10px;
  padding-left: 2.5rem;
  padding-right: 2.5rem;
  margin: 1rem 0;
  &.outlined {
    background: transparent;
    border: 2px solid $white; } }

.socials {
  .menu {
    @include breakpoint(small only) {
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      justify-content: center; } }

  font-size: 1.95em;
  a {
    padding: 0 .3em 0 0;
    &.facebook {
      color: #3B5998; }
    &.twitter {
      color: #1DA1F2; }
    &.xing {
      color: #026466; } } }

#stickyContactMobil {
  border-top: 1px solid $light-gray;
  background-color: darken($cloud,3%);
  padding: .9rem 1.4rem;
  .contact {
    color: $highlight;
    font-weight: 400;
    font-size: 1.25em; } }

header {
  .is-stuck {
    .headBig {
      height: 6rem;
      .logo {
        width: 70px; }
      .topNavi {
        li.contact {
          opacity: 1;
          visibility: visible;
          line-height: 1;
          text-decoration: none;
          display: block;
          padding: 0.9rem 1.4rem; } } }
    @include breakpoint(medium) {
      #nav {
        .contactwrap {
          opacity: 0;
          visibility: hidden;
          display: none; } } } }

  .headBig {
    transition: height .25s ease;
    box-shadow: 0px 3px 6px 0px rgba(0,0,0,0.16);
    position: relative;
    padding: .6rem 1rem;
    background-color: $white;
    height: 7.35rem;
    .logo {
      transition: height .25s ease;
      width: 80px;
      float: left; }
    .topNavi {
      float: right;
      font-weight: 400;
      li.contact {
        opacity: 0;
        visibility: hidden;
        transition:  opacity .25s ease-in-out; }
      a {
        color: $dark-gray; }
      i {
       color: $highlight; }
      &:hover {
        color: $highlight; } } }

  .title-bar {
    padding: .6rem 1rem;
    font-weight: 400;
    font-size: 1.15em;
    border-bottom: 6px solid $cloud;
    height: 10vh;
    position: relative;
    @include breakpoint(landscape) {
      height: 20vh; }
    .logo {
      height: 100%;
      img {
        height: 100%; } }
    &.opened {
      border-bottom: 6px solid $grey; }
    .hamburger {
      margin-left: .5rem;
      i {
        vertical-align: middle;
        font-size: 1.563rem; } } }
  .hero {
    @include breakpoint(medium) {
      margin-top: -5rem;
      z-index: -1; } }

  #nav {
    height: 90vh;
    @include breakpoint(landscape) {
      height: 80vh; }
    overflow: scroll;
    -ms-overflow-style: none;
    scrollbar-width: none;
    &::-webkit-scrollbar {
      display: none; }
    .is-dropdown-submenu .is-dropdown-submenu-parent.opens-right.is-active > a:after,
    .is-dropdown-submenu .is-dropdown-submenu-parent.opens-right > a:hover::after {
      border-color: transparent transparent transparent $white; }
    @include breakpoint(medium) {
      overflow: initial;
      height: auto;
      max-width: 75rem;
      margin-left: auto;
      margin-right: auto;
      margin-top: -1.5rem;
      //position: relative
      //top: 48px
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-end; }
    .mobile-breadcrumb {
      #crumb {
        display: inline-block;
        .breaker {
          color: $dark-gray;
          margin: 0 .2rem 0 .5rem; }
        span {
          font-size: .875rem;
          font-weight: 400;
          color: $dark-gray;
          position: relative;
          &:last-of-type {
            color: $blue;
            &:before {
              font-family: 'Font Awesome 5 Pro';
              font-weight: 900;
              font-size: 2rem;
              content: "\f0da";
              color: $white;
              position: absolute;
              transform: rotate(-90deg);
              margin: 7px 41%; } } } } }
    .menu {
      font-weight: 400;
      li {
        &.home {
          color: $highlight;
          a {
            color: $highlight;
            &:hover {
              color: $dark-gray; } } }
        a {
          color: $dark-gray;
          &:hover {
            color: $highlight; } } } }

    .medium-horizontal {
      @include breakpoint(medium) {
        box-shadow: 0px 3px 6px 0px rgba(0,0,0,0.16);
        > li:before {
          content: "";
          height: 70%;
          position: absolute;
          right: 0;
          top: 15%;
          border-right: 1px solid $light-gray; }
        > li:last-child:before,
        > li:first-child:before,
        > li.is-dropdown-submenu-parent > a::after {
          display: none; }
        > li.is-dropdown-submenu-item &.is-active {
          a:hover {
            color: $cloud; } }

        .highlight {
          border-radius: 10px 10px 0 0;
          margin-top: -10px;
          background-color: $cloud;
          > a {
            font-size: 1.1em;
            margin-top: 8px; }
          &::after {
            content: "";
            height: 70%;
            position: absolute;
            left: 0;
            top: 15%;
            border-left: 1px solid $light-gray; } }
        background-color: $cloud;
        border-radius: 10px;
        width: fit-content;
        li {
          .is-active > a {
            color: $cloud;
            &:hover {
              color: $cloud; } } } } }
    .tabs {
      border: 0;
      .tabs-title {
        > a {
          font-size: 1rem;
          color: $dark-gray;
          font-weight: 400;
          padding: 1.25rem 1.25rem;
          @include breakpoint(medium) {
            padding: 1.25rem 1.5rem; }
          i {
            color: $highlight; } }
        > a:hover,
        > a:focus,
        > a[aria-selected='true'] {
          background: $cloud;
          color: $highlight; } } }

    .tabs-content {
      border-top: 1px solid $light-gray; }


    .contactwrap {
      @include breakpoint(medium) {
        justify-content: flex-end;
        display: flex;
        visibility: visible;
        opacity: 1;
        transition:  opacity .25s ease-in-out; }
      .contact {
        padding: .9rem 1.4rem;
        color: $highlight;
        font-weight: 400;
        font-size: 1.563em;
        border-bottom: 2px solid #fefefe;
        @include breakpoint(medium) {
          background-color: $cloud;
          border-radius: 10px;
          font-size: 1em;
          padding: .5rem .9rem;
          display: inline;
          margin-top: 1rem;
          border-bottom: 0; } } }

    @include breakpoint(small only) {
      background-color: $cloud;
      .drilldown {
        li {
          border-bottom: 2px solid $cloud; } }
      .mobileSubmenu {
          li {
            border-bottom: 2px solid $white; }
          i {
            color: $highlight; } }
      .search {
        border-bottom-color: $highlight; } } }

  .search,
  .login,
  #search,
  #login,
  #toolbox,
  #tabsTools {
    button {
      font-size: 1.5rem;
      color: $blue;
      margin-right: .75rem;
      &.close-button {
        position: static; } }
    .input-group-field {
      margin: 0 .25rem;
      color: $highlight;
      font-size: 1.5rem;
      border: none;
      border-bottom: 2px solid $highlight;
      box-shadow: none;
      background-color: transparent;
      &::placeholder {
        color: $dark-gray; } } }

  #search,
  #login,
  #toolbox {
    display: none;
    position: absolute;
    width: 100%;
    &.show {
      display: block; }
    .overlay {
      z-index: 99;
      position: absolute;
      left: 0;
      right: 0;
      padding: 2rem 0;
      background-color: transparentize($white, .1);
      box-shadow: 0 4px 2px -2px transparentize($dark-gray, .87);
      min-height: 105px; } }

  #toolbox,
  #tabsTools {
    ul.navToolbox {
      margin-top: .5rem;
      @include breakpoint(medium) {
        margin-bottom: 0; }
      font-weight: 400;
      font-size: .875rem;
      list-style-type: none;
      column-count: 2;
      column-gap: 1rem;
      a {
        color: $dark-gray;
        &:hover {
          color: $blue; } } } } }

main {
  margin: 2rem 0 0 0;
  #sideNav {
    background: $cloud;
    box-shadow: 0px 3px 6px 0px rgba(0,0,0,0.16);
    font-weight: 300;
    hyphens: auto;
    li {
      border-bottom: 1px solid transparentize($dark-gray, .93);
      a {
        color: $dark-gray;
        &:hover {
          color: $highlight;
          font-weight: 400; } }
      &.is-active {
        > a:first-of-type {
          color: $highlight;
          font-weight: 400;
          background: none; } } }
    .nested {
      background-color: transparentize($dark-gray, .93);
      margin: 0;
      padding: .6rem 0;
      > li {
        border-bottom: none; } }

    .is-accordion-submenu a {
      padding: 0.45rem 1.4rem;
      font-size: .95rem; } }

  .caw-block {
    padding: {
      //top: 2.4rem
      bottom: 2.4rem; }
    a {
      font-weight: 400;
      position: relative;
      &:hover {
        color: darken($highlight, 10); }
      &.button {
        &::after {
          position: absolute;
          font-family: 'Font Awesome 5 Pro';
          font-weight: 900;
          font-size: 1.25rem;
          content: "\f0da";
          opacity: 0;
          top: 10px;
          right: -20px;
          transition: 0.5s;
          color: $white;
          right: 16px; }

        &:hover {
          color: $white;
          font-weight: 600;
          padding-right: 3rem;
          transition:  font-weight .25s ease-in-out, padding-right .25s ease-in-out, }
        &:hover:after {
          opacity: 1; }
        &:active {
          background: lighten($highlight, 10); } } }

    p + a.button {
      margin-top: 2rem; }
    &.intro {
      .block-headline {
        margin-bottom: 1rem; } }

    &.entdecken {
      height:  31.5rem;
      padding-top: 2.4rem;
      background: rgb(1,68,149);
      background: url(/img/charts.svg) center bottom -5.5rem no-repeat, $gardient1;
      background-size: 29rem, cover;
      .block-text {
        color: $white;
        h2 {
          color: $white;
          font-weight: 400; }
        .button {
          width: 70%; } }

      @include breakpoint(medium) {
        height:  auto;
        background: url(/img/charts.svg) right bottom -5.5rem no-repeat, $gardient1;
        background-size: 29rem, cover; }
      @include breakpoint(large) {
        background: url(/img/charts.svg) left 80% bottom -5.5rem no-repeat, $gardient1;
        background-size: 31rem, cover; } }
    &.onlinetools {
      padding-top: 2.4rem;
      background: url(/img/map.svg) center center no-repeat;
      background-size: cover;
      .block-text {
        h2 {
          color: $dark-gray; } } }
    &.slideboxes {
      position: relative;
      background-color: $cloud;
      .owl-theme .owl-nav [class*='owl-']:hover {
        background: transparent;
        text-decoration: none;
        i {
          color: darken($highlight, 10); } }
      .owl-prev, .owl-next {
        width: 15px;
        height: 100px;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        display: block !important;
        border: 0px solid black; }
      .owl-prev {
        left: -14px; }
      .owl-next {
        right: -14px; }
      .owl-prev i, .owl-next i {
        color: $highlight;
        font-size: 2.5rem; } }

    .owl-stage-outer {
        padding-top: 40px;
        overflow: hidden;
        .owl-stage {
          display: flex;
          .owl-item {
            padding: 1rem;
            background-color: $white;
            .icon {
              height: auto;
              width: auto;
              display: block;
              position: relative;
              text-align: center;
              i {
                background: $gardient2;
                width: 68px;
                height: 68px;
                font-size: 34px;
                box-sizing: content-box;
                color: $white;
                border-radius: 50%;
                line-height: 68px;
                top: -49px;
                margin-left: -34px;
                position: absolute; } }
            .text {
              padding-top: 30px; } } } }
    img {
      margin-bottom: 1rem; }
    .block-booking {
      img {
        margin: 1rem 0; } }
    .block-headline {
      margin-bottom: 2rem; }
    .block-list {
      ul,li {
        padding: 0;
        margin: 0;
        list-style: none; }
      ul {
        li {
          margin: 1em;
          margin-left: 3em;
          &::before {
            font-family: 'Font Awesome 5 Pro';
            font-weight: 900;
            font-size: 1.25rem;
            float: left;
            margin-left: -1.5em;
            content: "\f0da";
            color: $highlight; } }
        li:first-of-type {
          margin-top: 0; }
        li:last-of-type {
          margin-bottom: 0; } } }
    .block-text {
      p:last-of-type {
        margin-bottom: 0; } }

    .block-accordion {
      .accordion {
        margin-bottom: 0;
        .accordion-item {
          .accordion-title {
            border-left: 3px solid $highlight;
            border-right: 0;
            font-size: 1rem;
            color: $dark-gray;
            font-weight: 400;
            &:hover,
            &:focus {
              background-color: transparent;
              color: $highlight; }
            &::before {
              font-family: 'Font Awesome 5 Pro';
              font-weight: 900;
              font-size: 1.25rem;
              content: "\f0da";
              color: $medium-gray;
              transform: rotate(90deg); } }

          &.is-active {
            > .accordion-title {
              color: $highlight;
              &::before {
                transform: rotate(-90deg);
                color: $highlight; } } }
          .accordion-content {
            background-color: $blue-light;
            border-left: 3px solid $medium-gray;
            border-right: 0; } } } }
    .pressTeaser {
      img {
        margin: 0; }
      .teaser:last-of-type {
        .breaker {
          display: none; } } }
    .cawSpiegel {
      .item {
        overflow: hidden;
        position: relative;
        box-shadow: 0px 3px 6px 0px rgba(0,0,0,0.16);
        span.size {
          font-weight: 400;
          font-size: 1.25rem;
          margin-top: 3rem;
          display: inline-block;
          color: $medium-gray;
          i {
            margin-left: .5rem;
            color: $highlight; } }
        &:after {
          position: absolute;
          content: "\f1c1";
          font-family: 'Font Awesome 5 Pro';
          font-weight: 300;
          font-size: 10rem;
          color: $cloud;
          right: 40px;
          bottom: -80px;
          z-index: -1; } } }

    .filterNavTop,
    .filterNavBottom {
      box-shadow: 0px 3px 6px 0px rgba(0,0,0,0.16);
      border-radius: 7px;
      padding-top: .5rem;
      padding-bottom: .5rem;
      .input-group {
        margin: 0;
        .input-group-field {
          max-width: 250px;
          margin-right: .45rem;
          border-radius: 7px;
          height: 2.1rem; } }
      .pagination {
        margin: 0;
        a {
          font-weight: 500;
          color: $medium-gray;
          &:hover {
            background: $cloud;
            color: $highlight;
            border-radius: 4px; } }
        .current {
          background: none;
          a {
            background: $cloud;
            color: $dark-gray;
            border-radius: 4px; } }

        .pagination-previous a::before,
        .pagination-next a::after,
        .pagination-previous.disabled::before,
        .pagination-next.disabled::after {
          font-family: 'Font Awesome 5 Pro';
          font-weight: 900;
          font-size: 1rem;
          content: "\f0d9";
          color: $highlight;
          line-height: 0;
          margin: 0 2px 0; }
        .pagination-next a::after,
        .pagination-next.disabled::after {
          content: "\f0da";
          margin: 0 0 0 2px; } }
      > .grid-x > .cell:last-of-type {
        border-left: 1px solid $cloud; }
      > .grid-x > .cell:first-of-type {
        border-left: 0; } }

    .filterNavTop {
      font-size: 1.2rem;
      color: $medium-gray;
      #filterBtnDialog {
        margin-top: .5rem;
        border-top: 1px solid $cloud;
        .updateBtn {
          i {
            color: $highlight; } }
        h5 {
          font-size: 1rem;
          line-height: 1.8rem; }
        .input-group-field {
          border-radius: 7px;
          height: 2.1rem;
          color: $dark-gray; } }
      .filterBtn {
        line-height: 2rem;
        i {
          font-size: 1.1rem;
          margin-right: .45rem; } } }

    .filterNavBottom {
      .input-group {
        .input-group-label {
          border: 0;
          background: transparent; }
        .input-group-field {
          max-width: 55px; }
        .input-group-button {
          i {
            color: $highlight; } } } }
    table {
      margin: 0;
      thead {
        background: $cloud; }
      thead, tbody, tfoot {
        border-right: 0;
        th {
          color: $highlight; } }
      tr {
        border-right: 0px;
        border-left: 2px solid $highlight; }
      td {
        font-weight: 400;
        i {
          font-size: 1.25rem;
          color: $highlight; } } } } }

#cookieconsent {
  p {
    margin: 0; }
  background-color: $cloud;
  ul,li {
    list-style-type: none;
    margin: 0; }
  .cookieConesentChoice {
    button, a {
      cursor: pointer;
      color: $highlight;
      font-weight: 400; } } }

#breadcrumbsTop,
#breadcrumbs {
  a {
    color: $dark-gray;
    &:hover {
      text-decoration: underline; }
    &:last-child {
      color: $highlight; }
    > i {
      color: $highlight;
      padding-right: .4rem; } }
  p > i {
    font-size: .7rem;
    padding: 0 .4rem .1rem .5rem; } }

#breadcrumbsTop {
  margin-bottom: 1.5rem; }

footer {
  background: {
    color: $space; }
  #breadcrumbs {
    background: $white;
    p {
      margin: 0; }
    .colorBar {
      height: .3em;
      background: -moz-linear-gradient(left, rgba($blue,1) 0%, rgba(146,205,180,0.93) 50%, rgba(198,196,90,0.9) 75%, rgba(249,187,0,0) 100%);
      background: -webkit-linear-gradient(left, rgba($blue,1) 0%,rgba($turkis,0.93) 50%,rgba(198,196,90,0.9) 75%,rgba($yellow,0) 100%);
      background: linear-gradient(to right, rgba(1,68,149,1) 0%,rgba(146,205,180,0.93) 50%,rgba(198,196,90,0.9) 75%,rgba(249,187,0,0) 100%);
      filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#014495', endColorstr='#00f9bb00',GradientType=1 ); } }
  .footerBoxes {
    margin-bottom: 2.5rem;
    .cell {
      border-right: 1px solid $light-gray;
      &:last-child {
        border: none; }
      ul {
        margin-left: 0;
        font-size: .8em;

        &.navBottom,
        &.navSitemap {
          list-style-type: none;
          li {
            a {
              color: $dark-gray;
              &:hover {
                color: $highlight; } }
            line-height: 2em;
            &:first-child {
              font-weight: 400; } } }
        &.navBottom {
          li {
            font-weight: 400; } } } } }
  .footerCopyright {
    border-top: 1px solid $light-gray;
    font-size: .8em;
    font-weight: 400;
    background-color: $cloud;
    //-padding-bottom: 4rem
    color: $highlight;
    //-+breakpoint(medium)
 } }    //padding-bottom: .8rem

.disapear {
  visibility: hidden;
  opacity: 0; }
